.banner {
  background: white;
  border-color: var(--color-brand);
  border-style: solid;
  border-width: 2px;
  border-radius: 1em;
  padding: 1em;

  display: flex;
  flex-direction: row;
  gap: 1em;

  position: fixed;
  top: 1em;

  left: 50%;
  transform: translateX(-50%);

  z-index: 999999;

  animation: 3s linear 0s infinite both running pulse;
}

@keyframes pulse {
  0%,
  100% {
    background-color: white;
  }

  50% {
    background-color: hsl(from var(--color-brand) h s 90%);
  }
}
