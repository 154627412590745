@import 'src/styles/variables';
@import 'src/styles/mixins';

.SelectList__item {
  @include flex-align-center;
  position: relative;

  justify-content: space-between;

  width: 98%;
  margin: 0.3rem;
  padding: 1.3rem 1.6rem;

  text-align: left;

  background-color: $white;
  outline: none;
  cursor: pointer;

  transition: 0.8s background-color;

  @include FocusShadow;

  &_PostIcon {
    margin-right: 3rem;
  }

  &:hover {
    background-color: $grey15;
  }

  &_Active {
    &::after {
      position: absolute;
      top: 50%;
      right: 15px;

      transform: translateY(-50%);

      content: url('../../../assets/icon/check.svg');
    }
  }

  &_WithSmallerIndent {
    margin: 0 0.3rem;
    padding: 0.75rem 1.3rem;

    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &_WithLeftPadding {
    padding-left: 3.2rem;
  }
}
