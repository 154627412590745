$manrope-font-path: '../assets/fonts/Manrope/manrope';
$material-symbols-font-path: '../assets/fonts/MaterialSymbolsOutlined/MaterialSymbolsOutlined';

// variable fonts
@supports (font-variation-settings: normal) {
  @font-face {
    font-weight: 200 800;
    font-family: Manrope;
    font-style: normal;
    src: url('#{$manrope-font-path}-variable-wght.ttf');
    font-display: swap;
  }

  @font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 100 700;
    src:
      url('#{$material-symbols-font-path}[FILL,GRAD,opsz,wght].woff2')
        format('woff2'),
      url('#{$material-symbols-font-path}[FILL,GRAD,opsz,wght].ttf');
  }
}

// fallbacks for browsers that don't support variable fonts
@supports not (font-variation-settings: normal) {
  @font-face {
    font-weight: 400;
    font-family: Manrope;
    font-style: normal;
    src: url('#{$manrope-font-path}-regular.woff'),
      url('#{$manrope-font-path}-regular.otf'),
      url('#{$manrope-font-path}-regular.ttf');
    font-display: swap;
  }

  @font-face {
    font-weight: 500;
    font-family: Manrope;
    font-style: normal;
    src: url('#{$manrope-font-path}-medium.woff'),
      url('#{$manrope-font-path}-medium.otf'),
      url('#{$manrope-font-path}-medium.ttf');
    font-display: swap;
  }

  @font-face {
    font-weight: 600;
    font-family: Manrope;
    font-style: normal;
    src: url('#{$manrope-font-path}-semibold.woff'),
      url('#{$manrope-font-path}-semibold.otf'),
      url('#{$manrope-font-path}-semibold.ttf');
    font-display: swap;
  }

  @font-face {
    font-weight: 700;
    font-family: Manrope;
    font-style: normal;
    src: url('#{$manrope-font-path}-bold.woff'),
      url('#{$manrope-font-path}-bold.otf'),
      url('#{$manrope-font-path}-bold.ttf');
    font-display: swap;
  }

  @each $weight in 100, 200, 300, 400, 500, 600, 700 {
    @font-face {
      font-family: 'Material Symbols Outlined';
      font-style: normal;
      font-weight: $weight;
      src: url('#{$material-symbols-font-path}[0,0,24,#{$weight}].woff2')
        format('woff2');
    }
  }
}
