.Toaster {
  position: relative;

  display: flex;
  align-items: center;

  &_Body {
    flex: 1 1;

    &_Message {
      margin-bottom: 2rem;
    }
  }
}
