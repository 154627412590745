@import 'src/styles/variables';
@import 'src/styles/mixins';

.Container {
  @include flex-align-center;

  position: sticky;
  top: 0;
  z-index: $ZOverModalContent;

  justify-content: space-between;

  min-height: 6.7rem;

  padding: 0 4rem;

  border-bottom: solid 0.5px $grey3;

  background-color: $white;

  &_Nav {
    display: flex;

    &_Item {
      @include flex-align-center;

      padding: 2.5rem 2.4rem;

      border-bottom: 2px solid $white;

      @include FocusShadow;

      &_Icon {
        margin-right: 0.8rem;
      }

      &_Active {
        border-bottom-color: $blue-primary;
      }
    }
  }

  &_DropDownWrap {
    position: relative;

    align-self: center;

    & > button {
      width: fit-content;
      padding: 0 1rem;
    }
  }

  &_Profile {
    width: 3.2rem;
    height: 3.2rem;

    border-radius: 50%;

    &_DropDown {
      position: absolute;
      top: 4rem;
      right: -2rem;

      width: 24.6rem;

      border: 1px solid $grey3;
      border-radius: 4px;

      box-shadow: 0 12px 30px 0 rgb(23 29 41 / 8%);
    }

    &_Icon {
      color: $purple-primary;
    }
  }
}

.Modal {
  width: 40vw;

  &_Description {
    margin-bottom: 5.6rem;
  }
}

.Test {
  display: flex;
  flex-direction: column;

  img {
    margin-top: 10px;
  }
}

@include forScreen($mobile-small-width, $tablet-width - 1) {
  .Container {
    min-height: 6rem;
    padding: 0 2rem;

    &_Nav {
      display: flex;
      flex-direction: column;

      &_Item {
        padding: 3rem 0;

        border-bottom: 1px solid $grey18;

        &_Active {
          border-bottom-color: $grey18;
        }
      }
    }
  }
}

@include forScreen(0, $tablet-width - 1) {
  .Container_DropDownWrap {
    width: 100%;
    margin-top: 1rem;
  }

  .Container_Profile_DropDown {
    position: static;

    width: 100%;
  }
}

@include forScreen($tablet-width, $desktop-width - 1) {
  .Container {
    &_Nav {
      display: none;
    }
  }
}
