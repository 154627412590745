@import 'src/styles/variables';
@import 'src/styles/mixins';

// TODO: MOVE TO MIXINS FILE
@mixin SubMenuContainer {
  width: 25.5rem;
  padding: 0.4rem 0;
  border: solid 1px $grey3;
  border-radius: 4px;

  background: $white;
  box-shadow: 0 12px 30px 0 rgb(23 29 41 / 8%);
}

@mixin SubMenuListItem {
  @include flex-justify-between;
  @include flex-align-center;
  padding: 0.6rem 0.5rem 0.6rem 1.6rem;

  text-align: left;

  &:not(.ListItemDisabled):hover {
    background-color: $grey15;
  }

  &:not(.ListItemDisabled):hover span {
    font-weight: 600;
  }
}

@mixin Input {
  width: 100%;
  height: 4rem;
  padding: 1.4rem 1.6rem;
  border: 1px solid $grey8;
  border-radius: 5px;

  font-size: 1.4rem;

  background-color: $white;

  outline: none;

  &:active,
  &:focus {
    border-color: $blue-primary;

    background-color: $white;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: inset 0 0 0 4.8rem $white !important;
  }

  &::placeholder {
    color: $grey1;
  }

  &_Disabled {
    border-color: $grey3;

    background-color: $grey3;

    pointer-events: none;
  }

  &_ReadOnly {
    &:focus,
    &:active {
      border-color: $grey8;
    }
  }

  &_Error {
    border-color: $red-secondary;
  }
}

@mixin TableRow {
  display: flex;
  align-items: center;

  background-color: $white;

  & > td {
    display: flex;
    width: 100%;
  }
}

@mixin LeftRightBorders {
  border-right: 1px solid $grey6;
  border-left: 1px solid $grey6;
}

@mixin TableRowMain {
  @include TableRow;

  margin-bottom: 0.8rem;
  padding: 2.1rem 0.8rem 2.1rem 2.4rem;

  border: 1px solid $grey6;
  border-radius: 5px;
}

@mixin TableRowSub {
  @include TableRow;
  padding: 1.6rem 0.8rem 1.6rem 2.4rem;

  @include LeftRightBorders;
}

@mixin TableRowSubOfSub {
  @include TableRow;
  padding: 0.8rem 0.8rem 0.8rem 2.4rem;

  @include LeftRightBorders;
}

@mixin LastExpandedRow {
  margin-bottom: 0.8rem;

  border-bottom: 1px solid $grey6;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

@mixin Menu {
  @include flex-column;
  position: absolute;

  z-index: $ZOverContent;

  align-items: flex-start;

  padding: 1.2rem 0;

  border: solid 1px $grey3;
  border-radius: 4px;

  background-color: $white;
  box-shadow: 0 12px 30px 0 rgb(23 29 41 / 8%);

  &_Item {
    display: flex;
    min-width: 21rem;

    padding: 0.8rem 1.4rem;

    &_Disabled {
      cursor: not-allowed;

      &:hover {
        background-color: $white;
      }
    }

    &:hover {
      background-color: $grey15;
    }
  }
}

@mixin PaginationWrapper {
  display: flex;
  justify-content: flex-end;

  padding-bottom: 6.8rem;
}

.TupleFieldsWithBin {
  @include flex-align-center;

  margin-bottom: 1.4rem;

  &_Field {
    width: 100%;

    &:not(:last-child) {
      margin-right: 1.6rem;
    }
  }

  &_Delete {
    display: flex;
    padding-right: 2.4rem;
    padding-left: 3.2rem;

    visibility: hidden;

    &_Visible {
      visibility: visible;
      margin-top: 2rem;
    }
  }
}

.GroupFieldWrapper {
  @include flex-justify-between;

  &_WithBottomMargin {
    margin-bottom: 2rem;
  }
}

.SingleFieldWrapper {
  margin-bottom: 2rem;
}

.FieldWrapperItem {
  width: 49%;
}
