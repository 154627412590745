@import 'src/styles/shared.module';

.Input {
  position: relative;

  width: 100%;

  &_PostIcon {
    position: absolute;
    top: 50%;
    right: 1.6rem;

    transform: translateY(-50%);
  }

  .Input_Native {
    @include Input;

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      margin: 0;

      -webkit-appearance: none;
    }

    &_HasPost {
      padding-right: 5.6rem;
    }

    &_ReadOnly {
      pointer-events: none;
    }

    &_WithEllipsis {
      text-overflow: ellipsis;
    }

    &_WithoutBorder {
      border: none;
    }
  }
}
