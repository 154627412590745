@import 'src/styles/variables';
@import 'src/styles/mixins';

.Container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 100vh;

  background-color: $grey14;

  &_Body {
    flex-grow: 1;
  }
}

@include forScreen($mobile-small-width, $tablet-width) {
  .Container_Body {
    width: 100%;
  }
}
