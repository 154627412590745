@import 'src/styles/variables';
@import 'src/styles/mixins';

.Overlay {
  @include fixed-top-left;
  z-index: $ZOverModalContent;

  max-height: 80vh;
  overflow-y: auto;

  background-color: rgba($black-secondary, 0.2);

  @include flex-centered;
  @include fullscreen;
}

.WithBiggestZIndex {
  z-index: $ZOverBlockly;
}

.Wrap {
  position: relative;

  max-height: 80vh;

  &_Close {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;

    &_Outside {
      top: -10%;
      right: -10%;
    }

    &_Fixed {
      position: fixed;
    }

    &_Btn, &_MultipleBtn {
      display: flex;

      padding: 0.8rem;

      border-radius: 5px;
    }
  }
}

.Modal {
  @include flex-column;

  padding: 5.6rem;
  border: 1px solid $grey6;

  border-radius: 5px;

  background-color: $white;

  &_WithoutPadding {
    padding: 0;
  }

  &_WithoutRoundedBorders {
    border-radius: 0;
  }

  &_WithMinWidth {
    min-width: 50vw;
  }

  &_BottomMargin {
    margin-bottom: 3rem;
  }

  &_Labels {
    @include flex-align-center;
    margin-bottom: 2.8rem;

    &_Left {
      text-align: left;
    }

    &_Right {
      margin-left: auto;

      > *:not(:first-child) {
        margin-left: 1.6rem;
      }
    }
  }

  &_Description {
    @include flex-align-center;

    width: 100%;
    margin-bottom: 5.6rem;

    text-align: left;

    & > svg {
      margin-right: 0.8rem;
    }

    &_HalfMargin {
      margin-bottom: 2.8rem;
    }
  }
}

@include forScreen($mobile-small-width, $desktop-width) {
  .Modal {
    align-items: center;
    max-width: 80vw;

    border: initial;

    &:not(.Modal_WithoutPadding) {
      padding: 3.5rem;
    }

    & > div {
      width: 100%;
    }

    &_Labels {
      margin-bottom: 2.4rem;

      &_Left {
        display: flex;
        justify-content: center;

        font-size: 2rem;

        span {
          text-align: center;
        }
      }
    }

    &_Description {
      span {
        font-size: 1.4rem;
        text-align: center;
      }
    }
  }
}

@include forScreen($mobile-small-width, $mobile-big-width - 1) {
  .Modal {
    &_Labels {
      margin-bottom: 3rem;
    }
  }

  .Wrap {
    &_Close {
      &_Outside {
        right: 0;
      }
    }
  }
}