@import 'src/styles/variables';

.Base {
  font-size: 1.4rem;
  text-align: left;
}

.withWhiteColor {
  color: $white;
}

.withGreyColor {
  color: $grey1;
}

.withRedColor {
  color: $red3;
}

.withBlueColor {
  color: $blue-primary;
}

.with10Font {
  font-size: 1rem;
}

.with12Font {
  font-size: 1.2rem;
}

.with14Font {
  font-size: 1.4rem;
}

.with15Font {
  font-size: 1.5rem;
}

.with16Font {
  font-size: 1.6rem;
}

.with17Font {
  font-size: 1.7rem;
}

.with18Font {
  font-size: 1.8rem;
}

.with20Font {
  font-size: 2rem;
}

.with24Font {
  font-size: 2.4rem;
}

.with30Font {
  font-size: 3rem;
}

.with36Font {
  font-size: 3.6rem;
}

.with48Font {
  font-size: 4.8rem;
}

.withCapitalize {
  text-transform: capitalize;
}

.withUpperCase {
  text-transform: uppercase;
}

.withMediumWeight {
  font-weight: 500;
}

.withSemiBoldWeight {
  font-weight: 600;
}

.withBoldWeight {
  font-weight: bold;
}

.withEllipsis {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.withPreLine {
  white-space: pre-line;
}

.asBlock {
  display: inline-block;
  width: 100%;
}

.isDisabled {
  color: rgb(0 0 0 / 30%);
}