@import 'src/styles/variables';

@mixin flex {
  display: flex;
}

@mixin flex-column {
  @include flex;
  flex-direction: column;
}

@mixin flex-justify-between {
  @include flex;
  justify-content: space-between;
}

@mixin flex-centered {
  @include flex;
  align-items: center;
  justify-content: center;
}

@mixin overflow-with-dots {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin flex-wrap {
  @include flex;
  flex-wrap: wrap;
}

@mixin flex-align-center {
  @include flex;
  align-items: center;
}

@mixin flex-align-end {
  @include flex;
  align-items: flex-end;
}

@mixin fullscreen {
  min-width: 100vw;
  min-height: 100vh;
}

@mixin fixed-top-left {
  position: fixed;
  top: 0;
  left: 0;
}

@mixin page-padding {
  padding-right: 8vw;
  padding-left: 8vw;
}

@mixin process-management-tabs-container {
  padding-top: 3.2rem;

  &_Empty {
    padding-top: 0;
  }
}

@mixin custom-scroll-bar {
  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 24px;

    background-color: $grey8;
  }
}

@mixin breakpoint($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin FocusAndActiveBackground($color) {
  &:focus,
  &:active {
    background-color: $color;
  }
}

@mixin FocusShadow {
  &:focus {
    box-shadow: 0 0 6px 0 $blue-secondary-5;
  }
}

@mixin forScreen($minSize, $maxSize) {
  @media (min-width: $minSize) and (max-width: $maxSize) {
    @content;
  }
}
