.Container {
  width: 0;
  height: 0;

  visibility: hidden;

  &_Visible {
    width: unset;
    height: unset;

    visibility: visible;
  }
}
