@import 'src/styles/variables';
@import 'src/styles/mixins';

.Hamburger_Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
}

@include forScreen($mobile-small-width, $tablet-width) {
  .Hamburger_Wrapper {
    justify-content: flex-start;
  }
}

@include forScreen($mobile-small-width, $tablet-width - 1) {
  .Hamburger_Wrapper {
    position: fixed;
    top: 6rem;
    left: 0;

    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding: 0 2rem;

    background-color: $grey15;
  }
}

@include forScreen($tablet-width, $desktop-width - 1) {
  .Hamburger_Wrapper {
    justify-content: flex-end;
  }
}

@include breakpoint($tablet-width) {
  .Hamburger_Icon,
  .Hamburger_Wrapper {
    display: none;
  }
}
