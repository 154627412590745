@import 'src/styles/mixins';

.Buttons {
  display: flex;
  justify-content: flex-end;

  &_WithMargin {
    margin-top: 5rem;
  }

  &_Button {
    min-width: 12.4rem;

    &:last-child {
      margin-left: 1.6rem;
    }
  }
}

@include forScreen($mobile-small-width, $tablet-width - 1) {
  .Buttons {
    justify-content: center;

    &_Button {
      width: 100%;
    }
  }
}
