@import 'src/styles/variables';
@import 'src/styles/mixins';

.Container {
  @include fixed-top-left;
  z-index: $ZOverBlocklysModals;

  width: 100%;
  height: 100%;

  &_InBlock {
    position: absolute;
  }

  &_Center {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  &_WithWhiteBackground {
    background-color: $white;
  }
}
