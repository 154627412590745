@import 'src/styles/mixins';

.Container {
  @include flex-centered;
  flex-direction: column;
  min-height: calc(100vh - 12rem);

  &_Img {
    margin-bottom: 8.8rem;
  }

  &_TextWrapper {
    text-align: center;
  }
}

@include forScreen($mobile-small-width, $tablet-width - 1) {
  .Container {
    padding-right: 3rem;
    padding-left: 3rem;

    &_Img {
      margin-bottom: 4.8rem;
    }

    &_Img img {
      width: 12rem;
      height: 12rem;
    }
  }
}
