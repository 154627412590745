@import 'src/styles/variables';
@import 'src/styles/mixins';

.Modal {
  width: 40vw;

  &_Description {
    margin-bottom: 5.6rem;
  }
}

@include forScreen($mobile-small-width, $desktop-width - 1) {
  .Modal {
    width: 100%;

    &_Description {
      margin-bottom: 4rem;
    }
  }
}
