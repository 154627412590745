@import 'src/styles/variables';

button.wrapper {
  width: 3.2rem;
  height: 3.2rem;

  .icon {
    color: $purple-primary;
  }

  &.disabled {
    cursor: not-allowed;

    .icon {
      color: grey;
    }
  }
}
