$ZHide: -1;
$ZOverContent: 1;
$ZModal: 2;
$ZOverModal: 3;
$ZOverModalContent: 4;
$ZOverBlockly: 999999;
$ZOverBlocklysModals: 1000000;
$ZOverAll: 999;

$screenSizeTabletSmall: 630px;

$minContentHeight: calc(100vh - 12.8rem);

$global-font-family: 'Manrope', sans-serif;

$shadow-1: 0 2px 8px 0 rgb(33 37 41 / 15%);
$shadow-2: 0 2px 8px 0 rgb(33 37 41 / 10%);

$black-primary: #171d29;
$black-secondary: #454a54;
$black3: #545a67;
$white: #fff;
$red-primary: #d0021b;
$red-secondary: #f66b79;
$red3: #e35c6a;
$red4: #ff5a5a;
$grey1: #81858b;
$grey2: #c8c9cc;
$grey3: #ededee;
$grey4: #fbfbfb;
$grey5: #dedede;
$grey6: #e9ecef;
$grey7: #868e96;
$grey8: #d9dfe5;
$grey9: #fbfbff;
$grey10: #f1f2ff;
$grey11: #ebf3fc;
$grey12: #f1f3f5;
$grey13: #f7f9fc;
$grey14: #fafafb;
$grey15: #f8f9fa;
$grey16: #f8f8f8;
$grey17: #949a9e;
$grey18: #dee2e6;
$purple-primary: #303778;
$blue-primary: #007aff;
$blue-primary-2: #0962c4;
$blue-primary-3: #0062cc;
$blue-secondary-1: #fbfbff;
$blue-secondary-2: #f1f2ff;
$blue-secondary-3: #dfe2fd;
$blue-secondary-4: #90c4fd;
$blue-secondary-5: #0078fb;
$blue-secondary-6: #cfe5fc;
$violet: #515ab4;
$blocks-blue1: #5b80a5;
$blue2: #5b67a4;
$green1: #5ba55b;
$green2: #5ba58c;
$green3: #5ba5a5;
$green4: #20dda5;
$purple1: #745ba5;
$purple2: #995ba5;
$pink1: #a55b80;
$pink2: #a55ba5;
$brown1: #a5745b;
$blue1: #007aff;
$yellow1: #fdbf2f;
$yellow2: #ffe57d;
$mobile-small-width: 375px;
$mobile-big-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
