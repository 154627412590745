@import 'src/styles/variables';

:global {
  .rc-tooltip {
    padding: 0;

    background-color: initial;
    opacity: 1;

    &-arrow {
      display: none;
    }

    &-inner {
      padding: 0.8rem 1rem;

      border: none;
      border-radius: 5px;

      color: $black-primary;
      font-size: 1.4rem;
      text-align: center;

      background-color: $white;
      box-shadow: $shadow-1;
    }
  }
}

.OverAll {
  z-index: $ZOverBlockly;
}
