@import 'src/styles/variables';
@import 'src/styles/mixins';

.Container {
  position: sticky;
  bottom: 0;

  z-index: 999;

  @include flex-align-center;
  justify-content: space-between;

  padding: 1rem 4rem;

  border-top: solid 0.5px $grey3;

  background-color: $white;

  &_Name {
    @include flex-align-center;

    &_Icon {
      display: flex;
    }

    &_Label {
      margin-left: 1rem;
    }
  }

  &_Powered {
    display: flex;

    &_Name {
      margin-left: 0.2rem;
    }
  }
}
