@import 'src/styles/shared.module';
@import 'src/styles/variables';

.SelectWrapper {
  position: relative;

  &__disabled {
    cursor: not-allowed;

    pointer-events: none;
  }

  &__ReadOnly {
    pointer-events: none;
  }

  &_List {
    position: absolute;
    top: 52px;
    left: 0;
    z-index: $ZModal;

    width: 100%;
    height: auto;
    max-height: 230px;
    border: 1px solid $grey2;
    border-radius: 5px;
    overflow-x: hidden;

    background-color: $white;

    &::-webkit-scrollbar {
      width: 0.6rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 24px;

      background-color: $grey8;
    }
  }

  input {
    cursor: pointer;
  }

  &:focus {
    & input {
      border-color: $blue-primary;
    }
  }
}

.BottomOfPage {
  position: unset;
  margin: 5px;
}

.Arrow {
  @include FocusShadow;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4rem;
  height: 4rem;

  background-color: transparent;

  &_Active {
    transform: rotate(180deg);
  }
}

.DropDown {
  position: absolute;
}

.NoSearchResults {
  padding: 1.3rem 1.6rem;
}
