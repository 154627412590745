@import './mixins';
@import './variables';
@import './fonts';
@import './toaster';

:root {
  --color-brand: #{$blue-primary};
  --color-danger: #{$red4};
}

* {
  margin: 0;
  padding: 0;

  outline: none;
  accent-color: $blue-primary;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*,
button,
input,
select,
textarea {
  color: $black-primary;
  font-weight: normal;
  font-family: $global-font-family;
}

html {
  box-sizing: border-box;

  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
}

a {
  text-decoration: none;
}

button {
  border: none;

  background-color: $white;
  cursor: pointer;
}

body {
  min-height: 100vh;
}
