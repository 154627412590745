@import './variables';

.redux-toastr.workoptima-toastr {
  .top-right {
    top: 4.8rem;
    right: 4.8rem;

    width: 35rem;
  }

  .toastr {
    min-height: initial;

    margin: 0;

    border-radius: 2px;

    box-shadow: 0 5px 23px 0 rgb(0 0 0 / 25%);
    opacity: 1;

    &:hover {
      box-shadow: 0 5px 23px 0 rgb(0 0 0 / 25%);
    }

    &:focus {
      outline: none;
    }

    &.rrt-error {
      background-color: $red4;
    }

    &.rrt-info {
      background-color: $blue-primary;
      opacity: 0.78;
    }

    &.rrt-dark-info {
      background-color: $grey17;
    }

    .rrt-left-container {
      display: none;
    }

    .rrt-middle-container {
      width: 100%;
      margin: 0;
      padding: 2rem;

      .rrt-title {
        display: none;
      }

      .rrt-text {
        display: none;
      }
    }
  }
}

@include forScreen($mobile-small-width, $tablet-width - 1) {
  .redux-toastr.workoptima-toastr {
    .top-right {
      top: 1.2rem;
      left: 50%;

      transform: translate(-50%, 0);
    }
  }
}

@include forScreen($tablet-width, $desktop-width - 1) {
  .redux-toastr.workoptima-toastr {
    .top-right {
      top: 2rem;
      right: 2rem;
    }
  }
}
