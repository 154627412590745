@import 'src/styles/mixins';
@import 'src/styles/variables';

.Modal {
  width: 60vw;

  &_Shortcuts {
    margin-bottom: 4rem;

    &_Item {
      @include flex-align-center;
      @include flex-justify-between;

      margin-bottom: 1.4rem;

      &_Keys {
        display: flex;

        &_Key {
          @include flex-align-center;
          height: 3rem;
          padding: 0 1rem;

          border: 1px dashed $blue-primary;

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
